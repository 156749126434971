import React from "react";
import Div100vh from "react-div-100vh";
import Image from "components/image-containers/Image/Image.component";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Header from "components/menus/Header/Header.component";
import { homeRoute } from "routes";
import { vinn404Image } from "assets/externalImages";

import { useStyles } from "./Status404.styles";

const Status404 = () => {
  const classes = useStyles();

  return (
    <Div100vh>
      <Header />
      <Grid container>
        <Grid item xs={12} md={6} className={classes.textContainer}>
          <div className={classes.textBox}>
            <Typography variant="h1" gutterBottom className={classes.text404}>
              404
            </Typography>
            <Typography variant="h4" className={classes.textSorry}>
              Sorry! We can’t find the page you’re looking for
            </Typography>
            <Button
              href={homeRoute}
              variant="contained"
              color="primary"
              data-cy="home-button"
            >
              Take me home
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageContainer}>
          <Image
            fill
            alt="rusty car"
            className={classes.image}
            src={vinn404Image}
          />
        </Grid>
      </Grid>
    </Div100vh>
  );
};
export default Status404;
