import PropTypes from "prop-types";
import Head from "next/head";
import { homePageOG } from "assets/externalImages";

const SEO = ({
  title,
  description,
  image,
  url,
  ogType,
  canonical,
  twitterCard,
}) => (
  <Head>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    {(canonical || url) && (
      <link rel="canonical" href={canonical || url.split("?")[0]} />
    )}
    {url && <meta property="og:url" content={url} />}
    <meta property="og:site_name" content="VINN" />
    <meta property="og:type" content={ogType} />

    {twitterCard && (
      <>
        {image ? (
          <meta name="twitter:card" content="summary_large_image" />
        ) : (
          <meta name="twitter:card" content="summary" />
        )}
        <meta name="twitter:site" content="@vinnauto" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {image && <meta name="twitter:image" content={image} />}
      </>
    )}
  </Head>
);

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  canonical: PropTypes.string,
  image: PropTypes.string,
  ogType: PropTypes.string,
  url: PropTypes.string,
  twitterCard: PropTypes.bool,
};

SEO.defaultProps = {
  image: homePageOG,
  ogType: "website",
  url: undefined,
  canonical: undefined,
  twitterCard: false,
};

export default SEO;
