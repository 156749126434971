import Status404 from "features/main/pages/staticPages/ErrorPages/Status404.component";
import NotFoundLanding from "features/admin/pages/LandingPages/NotFoundLanding/NotFoundLanding.component";
import SEO from "components/data-display/SEO/SEO.component";

import { useRouter } from "next/router";
import useUser from "hooks/useUser.hook";

export default function NotFoundPage() {
  const { user } = useUser();
  const router = useRouter();
  const domain = router.asPath.split("/")[1];
  if (domain === "admin" && user?.type === "Admin") {
    return (
      <>
        <SEO
          title="404 Not Found | VINN"
          description="The page you are looking for does not exist"
          url="https://www.vinnauto.com/404"
        />
        <NotFoundLanding />
      </>
    );
  }
  return (
    <>
      <SEO
        title="404 Not Found | VINN"
        description="The page you are looking for does not exist"
        url="https://www.vinnauto.com/404"
      />
      <Status404 />
    </>
  );
}
