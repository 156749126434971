import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  image: {
    maxHeight: "100vh",
    maxWidth: "50vw",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      maxHeight: "45vh",
      maxWidth: "100vw",
      width: "auto",
      height: "auto",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "flex-end",
    height: "calc(100vh - 66px)",
    width: "50vw",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      maxHeight: "calc(55vh - 66px)",
    },
    position: "relative",
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column",
    marginTop: "-2%",
    [theme.breakpoints.down("md")]: {
      height: "32vh",
    },
  },
  textBox: {
    width: 388,
    maxWidth: "100vw",
    [theme.breakpoints.down("md")]: {
      maxWidth: "60vw",
    },
  },
  text404: {
    fontSize: 96,
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.title.mobile,
    },
  },
  textSorry: {
    fontSize: 34,
    lineHeight: "42px",
    marginBottom: 40,
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.h3.fontSize,
      lineHeight: "20px",
      marginBottom: 15,
    },
  },
}));
