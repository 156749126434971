import React from "react";

import Typography from "@mui/material/Typography";
import AdminPortal from "features/admin/components/AdminPortal/AdminPortal.component";

const NotFoundLanding = () => (
  <div data-cy="not-found-page">
    <AdminPortal>
      <Typography variant="h1" align="center">
        404
      </Typography>
    </AdminPortal>
  </div>
);

export default NotFoundLanding;
